<template>
  <div class="home">
    <div class="news-HImg">
      <img src="@/assets/images/1551929445.jpg" />
    </div>
    <div class="container">
      <section class="met-editor clearfix" v-if="basicinfo != null">
        <p>
          <strong>{{ basicinfo.corporateName }}</strong>
        </p>
        <p>地址：{{ basicinfo.address }}</p>
        <p>联系电话：{{ basicinfo.phone }}</p>
        <p>邮箱：{{ basicinfo.email }}</p>
        <Map :basicinfo="basicinfo"></Map>
      </section>
    </div>
  </div>
</template>
<script>
import Map from "@/views/about/Map.vue";
export default {
  name: "Contact",
  props: ["basicinfo"],
  components: { Map },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
strong {
  font-weight: bold;
}
.home {
  background-color: #ffffff;
  color: #2a333c;
}
.news-HImg img {
  width: 100%;
  height: 200px;
}

.met-editor {
  margin: 30px 0px;
  font-size: 16px;
}
</style>

<template>
  <div class="hello">
    <!--百度地图-->
    <baidu-map
      class="bm-view"
      :center="center"
      :zoom="zoom"
      @ready="handler"
      :auto-resize="true"
      :scroll-wheel-zoom="true"
    >
      <bm-marker
        v-for="(item, index) in points"
        :key="index"
        :position="{ lng: item.lng, lat: item.lat }"
        @click="
          clickHandler()
        "
      >
        <bm-info-window :title="title" :position="{lng: center.lng, lat: center.lat}"   :show="show" @close="infoWindowClose()" @open="infoWindowOpen()"></bm-info-window>
      </bm-marker>
    </baidu-map>
  </div>
</template>

<script>
export default {
  props:["basicinfo"],
  data() {
    return {
      center: { lng: 110.346557, lat: 25.246707 },
      zoom: 14,
      points: [],
      windowposition: {},
      title: "",      //地图弹窗信息
      show: false,
    };
  },
  created:function(){
    this.title = this.basicinfo.corporateName + "<br/>电话：" + this.basicinfo.phone + "<br/>地址：" + this.basicinfo.address
  },
  mounted() {
    this.addPoints();
    this.clickHandler();
  },
  methods: {
    // 点击点坐标赋值
    lookDetail(){
      this.show = true;
      //this.infoWindow.info = marker;
    },
    // 关闭弹窗
    infoWindowClose() {
      this.show = false
    },
    // 打开弹窗
    infoWindowOpen() {
      this.show = true
    },

    //地图实例
    handler({ BMap, map }) {
      this.center.lng = 110.346557;
      this.center.lat = 25.246707;
      this.zoom = 18;
    },
    clickHandler() {
      this.show = true;
    },
    addPoints() {
      const points = [];
      const position = {
        lng: 110.346557,
        lat: 25.246707,
        title: this.title,
        phone: this.phone,
        content: this.content,
      };
      points.push(position);
      this.points = points;
    },
  },
};
</script>

<style scoped>
.bm-view {
  width: 80%;
  height: 700px;
  border: #ccc solid 1px;
}
</style>
